<template>
  <div>
    <!-- Mobile nav -->
    <b-row class="d-md-none" style="background-color: black; position: fixed; z-index: 200; width: 100%;">
      <nav class="w-100 col-12 no-padding">
        <router-link to="/" class="mr-auto border-none no-padding bg-color-none">
          <img id="logo" style="margin-top: 15px; width: 220px; padding-bottom: 15px; margin-left: 15px;"
            src="@/assets/addp_logo_white.png" />
        </router-link>
        <div style="position: fixed; z-index: 220; width: 100%; background-color: black;" v-show="mobileNavActive">

          <router-link class="nav-link text-white text-decoration-none"
            to="/category/dealer-websites">WEBSITES</router-link>
          <router-link class="nav-link text-white text-decoration-none" to="/category/digital-advertising">DIGITAL
            ADVERTISING</router-link>
          <router-link class="nav-link text-white text-decoration-none" to="/category/digital-retailing">DIGITAL
            RETAILING</router-link>
          <div>
            <a href="#" @click.prevent="toggleMarketplaceServicesDropdown()"
              :class="{ 'nav-link-hover': marketplaceServicesDropdownActive || marketplaceServicesHighlighted }"
              class="nav-link text-white text-decoration-none">MARKETPLACE SERVICES
              <b-icon-chevronDown v-show="!marketplaceServicesDropdownActive"
                style="width: 20px; height: 15px; margin-bottom: 0px;"></b-icon-chevronDown>
              <b-icon-chevronUp v-show="marketplaceServicesDropdownActive"
                style="width: 20px; height: 15px; margin-bottom: 0px;"></b-icon-chevronUp>
            </a>
            <div class="dropdown-active" v-show="marketplaceServicesDropdownActive"
              @click="closeMarketplaceServicesDropdown" style="position: absolute; z-index: 60; width: 100%;">
              <router-link to="/category/chat-sms" style="text-transform: uppercase;"
                class="dropdown nav-link text-white text-decoration-none">CHAT/SMS</router-link>
              <router-link to="/category/creative-assets" style="text-transform: uppercase;"
                class="dropdown nav-link text-white text-decoration-none">DIGITAL MERCHANDISING</router-link>
              <router-link to="/category/lead-enrichment" style="text-transform: uppercase;"
                class="dropdown nav-link text-white text-decoration-none">LEAD ENRICHMENT</router-link>
              <router-link to="/category/call-tracking" style=""
                class="dropdown nav-link text-white text-decoration-none">CALL TRACKING</router-link>

              <router-link to="/category/reputation-management" style=""
                class="dropdown nav-link text-white text-decoration-none">REPUTATION MANAGEMENT</router-link>
              <router-link to="/category/trade-in" style=""
                class="dropdown nav-link text-white text-decoration-none">TRADE-IN</router-link>
            </div>
          </div>
          <div>
            <a href="#" @click.prevent="toggleAboutDropdown()"
              :class="{ 'nav-link-hover': aboutDropdownActive || aboutHighlighted }"
              class="nav-link text-white text-decoration-none">ABOUT
              <b-icon-chevronDown v-show="!aboutDropdownActive"
                style="width: 20px; height: 15px; margin-bottom: 0px;"></b-icon-chevronDown>
              <b-icon-chevronUp v-show="aboutDropdownActive"
                style="width: 20px; height: 15px; margin-bottom: 0px;"></b-icon-chevronUp>
            </a>
            <div @click="closeAboutDropdown" :class="{ 'dropdown-shadow': indexActive }" v-show="aboutDropdownActive"
              style="position: absolute; z-index: 60; width: 100%;">
              <router-link to="/about-enterprise-services" style=""
                class="dropdown nav-link text-white text-decoration-none">ENTERPRISE SERVICES</router-link>
              <router-link to="/about-digital-spend-optimization" style=""
                class="dropdown nav-link text-white text-decoration-none">DIGITAL SPEND OPTIMIZATION</router-link>
              <a target="_blank" href="https://www.acuracomplianceguide.com/ClientLoginPages/Acura/Login.aspx"
                class="dropdown nav-link text-white text-decoration-none d-flex">
                <b-icon-boxArrowUpRight
                  style="width: 18px; height: 18px; margin-top: 2px; margin-right: 10px;"></b-icon-boxArrowUpRight>
                Regional Marketing Program Login
              </a>
              <!-- <router-link to="/about-the-program" style=""
                class="dropdown nav-link text-white text-decoration-none">ABOUT THE PROGRAM</router-link>
              <router-link to="/how-to-enroll" style="" class="dropdown nav-link text-white text-decoration-none">HOW TO
                ENROLL</router-link>
              <router-link to="/frequently-asked-questions" style=""
                class="dropdown nav-link text-white text-decoration-none">FAQ</router-link> -->
            </div>
            <a href="#" @click.prevent="openSupport()" class="nav-link text-white text-decoration-none"
              to="/support">SUPPORT</a>
          </div>

        </div>
      </nav>
    </b-row>
    <!-- Mobile nav -->

    <!-- Desktop nav -->
    <b-row class="d-none d-md-flex justify-content-center" style="background-color: black;">
      <nav class="d-md-flex w-100 col-12" style="max-width: 1200px;">
        <router-link to="/" class="mr-auto border-none no-padding bg-color-none">
          <img id="logo" style="margin-top: 15px;" src="@/assets/addp_logo_white.png" />
        </router-link>
        <router-link class="nav-link text-white text-decoration-none"
          to="/category/dealer-websites">WEBSITES</router-link>
        <router-link class="nav-link text-white text-decoration-none" to="/category/digital-advertising">DIGITAL
          ADVERTISING</router-link>
        <router-link class="nav-link text-white text-decoration-none" to="/category/digital-retailing">DIGITAL
          RETAILING</router-link>

        <div>
          <a href="#" @click.prevent="toggleMarketplaceServicesDropdown()"
            :class="{ 'nav-link-hover': marketplaceServicesDropdownActive || marketplaceServicesHighlighted }"
            class="nav-link text-white text-decoration-none">MARKETPLACE SERVICES
            <b-icon-triangle-fill rotate="180" v-show="!marketplaceServicesDropdownActive"
              style="width: 6px; height: 7px; color: #e82c2a; margin-bottom: 4px; margin-left: 3px;"></b-icon-triangle-fill>
            <b-icon-triangle-fill v-show="marketplaceServicesDropdownActive"
              style="width: 6px; height: 7px; color: #e82c2a; margin-bottom: 4px; margin-left: 3px;"></b-icon-triangle-fill>
          </a>
          <div @click="closeMarketplaceServicesDropdown" class="dropdown-shadow"
            v-show="marketplaceServicesDropdownActive" style="position: absolute; z-index: 60;">

            <router-link to="/category/chat-sms" style="text-transform: uppercase;"
              class="dropdown nav-link text-white text-decoration-none">CHAT/SMS</router-link>
            <router-link to="/category/digital-merchandising" style="text-transform: uppercase;"
              class="dropdown nav-link text-white text-decoration-none">DIGITAL MERCHANDISING</router-link>
            <router-link to="/category/lead-enrichment" style="text-transform: uppercase;"
              class="dropdown nav-link text-white text-decoration-none">LEAD ENRICHMENT</router-link>

            <router-link to="/category/call-tracking" style=""
              class="dropdown nav-link text-white text-decoration-none">CALL TRACKING</router-link>
            <router-link to="/category/reputation-management" style=""
              class="dropdown nav-link text-white text-decoration-none">REPUTATION MANAGEMENT</router-link>
            <router-link to="/category/trade-in" style=""
              class="dropdown nav-link text-white text-decoration-none">TRADE-IN</router-link>
          </div>
        </div>

        <div>
          <a href="#" @click.prevent="toggleAboutDropdown()"
            :class="{ 'nav-link-hover': aboutDropdownActive || aboutHighlighted }"
            class="nav-link text-white text-decoration-none">ABOUT
            <b-icon-triangle-fill rotate="180" v-show="!aboutDropdownActive"
              style="width: 6px; height: 7px; color: #e82c2a; margin-bottom: 4px; margin-left: 3px;"></b-icon-triangle-fill>
            <b-icon-triangle-fill v-show="aboutDropdownActive"
              style="width: 6px; height: 7px; color: #e82c2a; margin-bottom: 4px; margin-left: 3px;"></b-icon-triangle-fill>
          </a>
          <div @click="closeAboutDropdown" class="dropdown-shadow" v-show="aboutDropdownActive"
            style="position: absolute; z-index: 60;">
            <!-- <router-link to="/about-the-program" style=""
              class="dropdown nav-link text-white text-decoration-none">ABOUT THE PROGRAM</router-link> -->
            <router-link to="/about-enterprise-services" style=""
              class="dropdown nav-link text-white text-decoration-none">ENTERPRISE SERVICES</router-link>
            <router-link to="/about-digital-spend-optimization" style="width: max-content;"
              class="dropdown nav-link text-white text-decoration-none">DIGITAL SPEND OPTIMIZATION</router-link>
            <a target="_blank" href="https://www.acuracomplianceguide.com/ClientLoginPages/Acura/Login.aspx"
              class="dropdown nav-link text-white text-decoration-none d-flex">
              <b-icon-boxArrowUpRight
                style="width: 18px; height: 18px; margin-top: 2px; margin-right: 10px;"></b-icon-boxArrowUpRight>
              Regional Marketing Program Login
            </a>
            <!-- <router-link to="/how-to-enroll" style="" class="dropdown nav-link text-white text-decoration-none">HOW TO
              ENROLL</router-link> -->
            <!-- <router-link to="/frequently-asked-questions" style=""
              class="dropdown nav-link text-white text-decoration-none">FAQ</router-link> -->
          </div>
        </div>
        <a href="#" @click.prevent="openSupport()" class="nav-link text-white text-decoration-none"
          to="/support">SUPPORT</a>
      </nav>
    </b-row>
    <!-- Desktop nav -->

  </div>
</template>

<script>

export default {
  name: 'the-nav',

  components: {

  },

  watch: {
    '$route.params.categorySlug': function () {
      this.$store.commit("closeAboutDropdown");
      this.$store.commit("closeMarketplaceServicesDropdown");
    },

    '$store.state.modalActive': function () {
      this.$store.commit("closeAboutDropdown");
      this.$store.commit("closeMarketplaceServicesDropdown");
    },
  },

  data() {
    return {
      marketplaceServicesCategories: ['chat-sms', 'digital-merchandising', 'lead-enrichment', 'call-tracking', 'reputation-management', 'trade-in'],
      aboutCategories: ['about-the-program', 'how-to-enroll', 'frequently-asked-questions', 'about-enterprise-services', 'about-digital-spend-optimization'],
    };
  },

  methods: {

    toggleAboutDropdown() {
      this.closeMarketplaceServicesDropdown();
      this.$store.commit("toggleAboutDropdown");
    },

    closeAboutDropdown() {
      this.$store.commit("closeAboutDropdown");
    },

    toggleMarketplaceServicesDropdown() {
      this.closeAboutDropdown();
      this.$store.commit("toggleMarketplaceServicesDropdown");
    },

    closeMarketplaceServicesDropdown() {
      this.$store.commit("closeMarketplaceServicesDropdown");
    },

    openSupport() {
      window.scrollTo(0, 0);
      this.$store.commit("closeEverything");
      this.$store.commit("toggleModal");
      this.$store.commit("toggleSupport");
    }

  },

  computed: {

    indexActive() {
      if (this.$route.name == 'Home') {
        return true;
      }
      return false;
    },

    aboutActive() {
      if (this.$route.name == 'How to Enroll') {
        return true;
      }
      return false;
    },

    mobileNavActive() {
      return this.$store.state.mobileNavActive;
    },

    aboutDropdownActive() {
      return this.$store.state.aboutDropdownActive ? true : false;
    },

    aboutHighlighted() {

      let route = this.$route.path.replace('/', '');

      if (this.aboutCategories.includes(route)) {
        return true;
      }
      return false;
    },

    marketplaceServicesHighlighted() {
      if (this.marketplaceServicesCategories.includes(this.$route.params.categorySlug)) {
        return true;
      }
      return false;
    },

    marketplaceServicesDropdownActive() {
      return this.$store.state.marketplaceServicesDropdownActive ? true : false;
    },

  }

}


</script>

<style scoped>
#logo {
  width: 200px;
}

#nav-container {
  display: none;
}

.border-none {
  border: none !important;
}

.bg-color-none {
  background-color: transparent !important;
}

.nav-link {
  font-size: .9rem;
  font-weight: 600;
  text-transform: uppercase;
  padding-top: 25px;
  padding-bottom: 25px;
}

.nav-link:focus {
  /* background-color: white; */
  color: black;
  /* background-color: rgb(65, 157, 223); */
  /* border-bottom: solid 3px rgb(23, 92, 142); */
  padding-bottom: 25px;
}

.nav-link:hover {
  background-color: white;
  color: #e82c2a !important;
  padding-bottom: 25px;
}

.nav-link-hover {
  background-color: white;
  color: black !important;
  padding-bottom: 25px;
}

.dropdown-shadow {
  -webkit-box-shadow: 0px 8px 9px 2px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 8px 9px 2px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 8px 9px 2px rgba(0, 0, 0, 0.15);
}

.router-link-exact-active {
  /* border-bottom: solid 3px rgb(23, 92, 142); */
  /* background-color: rgb(65, 157, 223); */
  background-color: white;
  color: black !important;
  padding-bottom: 22px;
}

.dropdown {
  padding-top: 15px;
  padding-bottom: 15px;
  /* background-color: rgb(65, 157, 223); */
  color: black !important;
  background-color: white;
  border: none;
}

.dropdown:hover {
  /* background-color: #dadada; */
  /* background-color: rgb(65, 157, 223); */
  background-color: rgb(230, 230, 230);
  color: #e82c2a !important;
  border: none !important;
  padding-bottom: 15px !important;
}

/* .dropdown:focus {
  background-color: rgb(202, 202, 202);
  color: black !important;
  background-color: #007cc0;
  border: none !important;
  padding-bottom: 15px !important;
} */

.no-padding {
  padding: 0px !important;
}

@media screen and (max-width: 768px) {
  #logo {
    width: 180px !important;
  }
}
</style>
